import { Tabs } from 'flowbite-react';
import { useCallback, useState } from 'react';

import RenderEvalContent from 'components/inClass/RenderEvalContent';
import { useSocketSubscribe } from 'hooks';
import { EvalData } from 'types';

const NotebookQuestion = ({ data }: { data: EvalData }) => {
  const [showHint, setShowHint] = useState<Record<string, boolean>>({});
  const [showAnswers, setShowAnswers] = useState<Record<string, boolean>>({});

  const revealHintHandler = useCallback((data: string) => {
    const parsedData = JSON.parse(data);
    const questionId = Object.keys(parsedData)[0];
    const value = parsedData[questionId];
    setShowHint((prev) => ({ ...prev, [questionId]: value }));
  }, []);

  useSocketSubscribe<string>('revealHintToClient', revealHintHandler);

  const revealAnswerHandler = useCallback((data: string) => {
    const parsedData = JSON.parse(data);
    const questionId = Object.keys(parsedData)[0];
    const value = parsedData[questionId];
    setShowAnswers((prev) => ({ ...prev, [questionId]: value }));
  }, []);

  useSocketSubscribe<string>('revealAnswerToClient', revealAnswerHandler);

  return (
    <div className='p-4 m-4 text-white border rounded-lg shadow-lg border-primary-850'>
      <div className='flex justify-between w-full mb-5'>
        <RenderEvalContent data={data?.question_description} />
        <span className='flex gap-3 text-black'>
          {showHint[data._id] && (
            <div className='p-1 text-sm bg-yellow-200 rounded-lg h-fit'>Hint Revealed</div>
          )}
          {showAnswers[data._id] && (
            <div className='p-1 text-sm bg-green-200 rounded-lg h-fit'>Answer Revealed</div>
          )}
        </span>
      </div>
      {showAnswers[data._id] || showHint[data._id] ? (
        <Tabs
          theme={{
            tabitemcontainer: {
              base: 'flex items-center  rounded-t-lg p-4 text-sm font-medium first:ml-0 focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500',
              styles: {
                default: 'bg-primary-850 text-white',
              },
            },
            tablist: {
              styles: {
                default: 'flex-wrap border-b border-gray-200 dark:border-gray-700 gap-x-1',
              },
              tabitem: {
                base: 'rounded-t-lg',
                styles: {
                  default: {
                    active: {
                      off: 'bg-primary-850 text-white p-2 hover:text-white hover:bg-gray-600 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                      on: 'bg-white text-primary-800 p-2 ',
                    },
                  },
                },
              },
            },
          }}
        >
          {showHint[data._id] && (
            <Tabs.Item title='Hint'>
              {data.hint_for_student && <RenderEvalContent data={data.hint_for_student} />}
            </Tabs.Item>
          )}
          {showAnswers[data._id] && (
            <Tabs.Item title='Answer'>
              {data.answer.map((answer, idx) => (
                <span key={idx}>
                  <RenderEvalContent data={[answer]} />
                </span>
              ))}
            </Tabs.Item>
          )}
        </Tabs>
      ) : (
        ''
      )}
    </div>
  );
};

export default NotebookQuestion;
